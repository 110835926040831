// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-us-page-about-us-page-jsx": () => import("./../../../src/pages/AboutUsPage/AboutUsPage.jsx" /* webpackChunkName: "component---src-pages-about-us-page-about-us-page-jsx" */),
  "component---src-pages-about-us-page-sections-product-section-jsx": () => import("./../../../src/pages/AboutUsPage/Sections/ProductSection.jsx" /* webpackChunkName: "component---src-pages-about-us-page-sections-product-section-jsx" */),
  "component---src-pages-about-us-page-sections-team-section-jsx": () => import("./../../../src/pages/AboutUsPage/Sections/TeamSection.jsx" /* webpackChunkName: "component---src-pages-about-us-page-sections-team-section-jsx" */),
  "component---src-pages-about-us-page-sections-work-section-jsx": () => import("./../../../src/pages/AboutUsPage/Sections/WorkSection.jsx" /* webpackChunkName: "component---src-pages-about-us-page-sections-work-section-jsx" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-components-components-jsx": () => import("./../../../src/pages/Components/Components.jsx" /* webpackChunkName: "component---src-pages-components-components-jsx" */),
  "component---src-pages-components-sections-section-basics-jsx": () => import("./../../../src/pages/Components/Sections/SectionBasics.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-basics-jsx" */),
  "component---src-pages-components-sections-section-carousel-jsx": () => import("./../../../src/pages/Components/Sections/SectionCarousel.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-carousel-jsx" */),
  "component---src-pages-components-sections-section-completed-examples-jsx": () => import("./../../../src/pages/Components/Sections/SectionCompletedExamples.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-completed-examples-jsx" */),
  "component---src-pages-components-sections-section-download-jsx": () => import("./../../../src/pages/Components/Sections/SectionDownload.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-download-jsx" */),
  "component---src-pages-components-sections-section-examples-jsx": () => import("./../../../src/pages/Components/Sections/SectionExamples.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-examples-jsx" */),
  "component---src-pages-components-sections-section-javascript-jsx": () => import("./../../../src/pages/Components/Sections/SectionJavascript.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-javascript-jsx" */),
  "component---src-pages-components-sections-section-login-jsx": () => import("./../../../src/pages/Components/Sections/SectionLogin.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-login-jsx" */),
  "component---src-pages-components-sections-section-navbars-jsx": () => import("./../../../src/pages/Components/Sections/SectionNavbars.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-navbars-jsx" */),
  "component---src-pages-components-sections-section-notifications-jsx": () => import("./../../../src/pages/Components/Sections/SectionNotifications.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-notifications-jsx" */),
  "component---src-pages-components-sections-section-pills-jsx": () => import("./../../../src/pages/Components/Sections/SectionPills.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-pills-jsx" */),
  "component---src-pages-components-sections-section-tabs-jsx": () => import("./../../../src/pages/Components/Sections/SectionTabs.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-tabs-jsx" */),
  "component---src-pages-components-sections-section-typography-jsx": () => import("./../../../src/pages/Components/Sections/SectionTypography.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-typography-jsx" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-faq-page-faq-page-jsx": () => import("./../../../src/pages/FaqPage/FaqPage.jsx" /* webpackChunkName: "component---src-pages-faq-page-faq-page-jsx" */),
  "component---src-pages-faq-page-sections-faq-main-content-jsx": () => import("./../../../src/pages/FaqPage/Sections/FaqMainContent.jsx" /* webpackChunkName: "component---src-pages-faq-page-sections-faq-main-content-jsx" */),
  "component---src-pages-faq-page-sections-more-references-content-jsx": () => import("./../../../src/pages/FaqPage/Sections/MoreReferencesContent.jsx" /* webpackChunkName: "component---src-pages-faq-page-sections-more-references-content-jsx" */),
  "component---src-pages-how-to-stake-page-how-to-stake-page-jsx": () => import("./../../../src/pages/HowToStakePage/HowToStakePage.jsx" /* webpackChunkName: "component---src-pages-how-to-stake-page-how-to-stake-page-jsx" */),
  "component---src-pages-how-to-stake-page-sections-how-to-stake-main-content-jsx": () => import("./../../../src/pages/HowToStakePage/Sections/HowToStakeMainContent.jsx" /* webpackChunkName: "component---src-pages-how-to-stake-page-sections-how-to-stake-main-content-jsx" */),
  "component---src-pages-howtostake-js": () => import("./../../../src/pages/howtostake.js" /* webpackChunkName: "component---src-pages-howtostake-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-page-landing-page-jsx": () => import("./../../../src/pages/LandingPage/LandingPage.jsx" /* webpackChunkName: "component---src-pages-landing-page-landing-page-jsx" */),
  "component---src-pages-landing-page-sections-contact-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/ContactSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-contact-section-jsx" */),
  "component---src-pages-landing-page-sections-delegation-details-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/DelegationDetailsSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-delegation-details-section-jsx" */),
  "component---src-pages-landing-page-sections-faq-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/FaqSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-faq-section-jsx" */),
  "component---src-pages-landing-page-sections-how-to-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/HowToSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-how-to-section-jsx" */),
  "component---src-pages-landing-page-sections-product-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/ProductSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-product-section-jsx" */),
  "component---src-pages-landing-page-sections-team-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/TeamSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-team-section-jsx" */),
  "component---src-pages-sample-page-js": () => import("./../../../src/pages/sample-page.js" /* webpackChunkName: "component---src-pages-sample-page-js" */),
  "component---src-pages-sample-page-sample-page-jsx": () => import("./../../../src/pages/SamplePage/SamplePage.jsx" /* webpackChunkName: "component---src-pages-sample-page-sample-page-jsx" */),
  "component---src-pages-sample-page-sections-product-section-jsx": () => import("./../../../src/pages/SamplePage/Sections/ProductSection.jsx" /* webpackChunkName: "component---src-pages-sample-page-sections-product-section-jsx" */),
  "component---src-pages-sample-page-sections-section-basics-jsx": () => import("./../../../src/pages/SamplePage/Sections/SectionBasics.jsx" /* webpackChunkName: "component---src-pages-sample-page-sections-section-basics-jsx" */),
  "component---src-pages-sample-page-sections-section-carousel-jsx": () => import("./../../../src/pages/SamplePage/Sections/SectionCarousel.jsx" /* webpackChunkName: "component---src-pages-sample-page-sections-section-carousel-jsx" */),
  "component---src-pages-sample-page-sections-team-section-jsx": () => import("./../../../src/pages/SamplePage/Sections/TeamSection.jsx" /* webpackChunkName: "component---src-pages-sample-page-sections-team-section-jsx" */),
  "component---src-pages-sample-page-sections-work-section-jsx": () => import("./../../../src/pages/SamplePage/Sections/WorkSection.jsx" /* webpackChunkName: "component---src-pages-sample-page-sections-work-section-jsx" */)
}

